import React, { useState } from 'react';
import { SavedEvaluation } from '../types/assessment';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { 
  X, User, MapPin, Calendar, Building, 
  CheckCircle2, XCircle, ChevronDown, ChevronUp,
  AlertTriangle, FileDown
} from 'lucide-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import GapAnalysis from './GapAnalysis';

interface EvaluationDetailProps {
  evaluation: SavedEvaluation;
  onClose: () => void;
}

const pillarNames = {
  humanIntegrity: 'Integridad Humana',
  formalityDeficiency: 'Formalidad',
  teamworkDeficiency: 'Trabajo en Equipo',
  competencyDeficiency: 'Competencia',
  questioningAttitudeDeficiency: 'Actitud Cuestionadora',
};

export default function EvaluationDetail({ evaluation, onClose }: EvaluationDetailProps) {
  const [expandedStages, setExpandedStages] = useState<Record<string, boolean>>({});

  const toggleStage = (stageId: string) => {
    setExpandedStages(prev => ({
      ...prev,
      [stageId]: !prev[stageId]
    }));
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'PPP', { locale: es });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-5xl max-h-[90vh] overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b">
          <div>
            <h2 className="text-2xl font-semibold">{evaluation.processName}</h2>
            <p className="text-sm text-gray-500 mt-1">
              Evaluación completada el {formatDate(evaluation.completedAt)}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <PDFDownloadLink
              document={<PDFDocument evaluation={evaluation} />}
              fileName={`evaluacion-${evaluation.processName.toLowerCase().replace(/\s+/g, '-')}.pdf`}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              {({ loading }) => (
                <>
                  <FileDown className="h-5 w-5 mr-2" />
                  {loading ? 'Generando PDF...' : 'Descargar PDF'}
                </>
              )}
            </PDFDownloadLink>
            <button
              onClick={onClose}
              className="p-2 text-gray-500 hover:text-gray-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="overflow-y-auto p-6">
          {/* Información General */}
          <div className="bg-gray-50 rounded-lg p-6 mb-8">
            <h3 className="text-lg font-semibold mb-4">Información General</h3>
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="flex items-center">
                  <User className="h-5 w-5 text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Evaluador</p>
                    <p className="font-medium">{evaluation.assessmentInfo.evaluatorName}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Fecha</p>
                    <p className="font-medium">{formatDate(evaluation.assessmentInfo.date)}</p>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex items-center">
                  <MapPin className="h-5 w-5 text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Ubicación</p>
                    <p className="font-medium">{evaluation.assessmentInfo.location}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Building className="h-5 w-5 text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Área</p>
                    <p className="font-medium">{evaluation.assessmentInfo.area}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Etapas del Proceso */}
          <div className="space-y-6">
            <h3 className="text-xl font-semibold mb-4">Etapas del Proceso</h3>
            {evaluation.stages.map((stage, index) => (
              <div key={stage.id} className="border rounded-lg shadow-sm">
                <div 
                  className="p-4 bg-white cursor-pointer hover:bg-gray-50"
                  onClick={() => toggleStage(stage.id)}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="text-lg font-medium">
                        {index + 1}. {stage.name}
                      </h4>
                      <p className="text-gray-600 mt-1">{stage.description}</p>
                    </div>
                    {expandedStages[stage.id] ? (
                      <ChevronUp className="h-5 w-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-400" />
                    )}
                  </div>
                </div>

                {expandedStages[stage.id] && (
                  <div className="border-t p-4">
                    {/* Entendimiento y Procedimiento */}
                    <div className="grid grid-cols-2 gap-6 mb-6">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h5 className="font-medium text-gray-900 mb-2">
                          Entendimiento del Empleado
                        </h5>
                        <p className="text-gray-700">{stage.employeeUnderstanding}</p>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h5 className="font-medium text-gray-900 mb-2">
                          Procedimiento Formal
                        </h5>
                        <p className="text-gray-700">{stage.formalProcedure}</p>
                      </div>
                    </div>

                    {/* Análisis de Brechas */}
                    {stage.gapAnalysis && stage.gapAnalysis.length > 0 && (
                      <div className="mb-6">
                        <h5 className="font-medium text-gray-900 mb-3">
                          Análisis de Brechas
                        </h5>
                        <div className="space-y-4">
                          {stage.gapAnalysis.map((gap, gapIndex) => (
                            <div key={gapIndex} className="bg-gray-50 p-4 rounded-lg">
                              <div className="mb-2">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                  {gap.category}
                                </span>
                              </div>
                              <p className="text-gray-700 mb-2">{gap.description}</p>
                              <div className="mt-2">
                                <h6 className="text-sm font-medium text-gray-900 mb-1">
                                  Recomendaciones:
                                </h6>
                                <p className="text-gray-700">{gap.recommendations}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Evaluación de Pilares */}
                    <div>
                      <h5 className="font-medium text-gray-900 mb-3">
                        Evaluación de Pilares
                      </h5>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {Object.entries(evaluation.pillars[stage.id] || {}).map(([pillar, assessment]) => (
                          <div 
                            key={pillar}
                            className={`p-4 rounded-lg border ${
                              assessment.rating === 'efectivo'
                                ? 'bg-green-50 border-green-200'
                                : 'bg-red-50 border-red-200'
                            }`}
                          >
                            <div className="flex items-center justify-between mb-2">
                              <span className="font-medium">
                                {pillarNames[pillar as keyof typeof pillarNames]}
                              </span>
                              <div className="flex items-center">
                                {assessment.rating === 'efectivo' ? (
                                  <CheckCircle2 className="h-5 w-5 text-green-500" />
                                ) : (
                                  <XCircle className="h-5 w-5 text-red-500" />
                                )}
                              </div>
                            </div>
                            {assessment.observations && (
                              <div className="mt-2">
                                <p className="text-sm text-gray-700">
                                  {assessment.observations}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}