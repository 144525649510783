import React, { useState } from 'react';
import { useAssessmentStore } from '../store/assessmentStore';
import { SavedEvaluation } from '../types/assessment';
import { 
  CheckCircle2, XCircle, AlertCircle, Calendar, MapPin, 
  User, ChevronRight, Copy, Search, Filter
} from 'lucide-react';
import EvaluationDetail from './EvaluationDetail';

const pillarNames = {
  humanIntegrity: 'Integridad Humana',
  formalityDeficiency: 'Formalidad',
  teamworkDeficiency: 'Trabajo en Equipo',
  competencyDeficiency: 'Competencia',
  questioningAttitudeDeficiency: 'Actitud Cuestionadora',
};

export default function HistoricalEvaluations() {
  const { savedEvaluations, cloneEvaluation } = useAssessmentStore();
  const [selectedEvaluation, setSelectedEvaluation] = useState<SavedEvaluation | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'date' | 'name'>('date');

  if (savedEvaluations.length === 0) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">No hay evaluaciones guardadas</h3>
          <p className="mt-1 text-gray-500">Las evaluaciones completadas aparecerán aquí</p>
        </div>
      </div>
    );
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleClone = (e: React.MouseEvent, evaluationId: string) => {
    e.stopPropagation();
    cloneEvaluation(evaluationId);
  };

  const filteredEvaluations = savedEvaluations
    .filter(evaluation => 
      evaluation.processName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      evaluation.assessmentInfo.evaluatorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      evaluation.assessmentInfo.location.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime();
      }
      return a.processName.localeCompare(b.processName);
    });

  const calculateEffectiveness = (evaluation: SavedEvaluation) => {
    let effective = 0;
    let total = 0;

    evaluation.stages.forEach(stage => {
      Object.values(evaluation.pillars[stage.id] || {}).forEach(assessment => {
        total++;
        if (assessment.rating === 'efectivo') effective++;
      });
    });

    return total > 0 ? (effective / total) * 100 : 0;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-sm">
          {/* Header and Controls */}
          <div className="p-6 border-b">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Historial de Evaluaciones</h2>
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Buscar evaluaciones..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 w-64"
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <Filter className="h-5 w-5 text-gray-400" />
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value as 'date' | 'name')}
                    className="border border-gray-300 rounded-md py-2 pl-3 pr-8 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="date">Ordenar por fecha</option>
                    <option value="name">Ordenar por nombre</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Evaluations List */}
          <div className="divide-y divide-gray-200 max-h-[calc(100vh-16rem)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {filteredEvaluations.map((evaluation) => (
              <div
                key={evaluation.id}
                className="p-6 hover:bg-gray-50 transition-colors cursor-pointer"
                onClick={() => setSelectedEvaluation(evaluation)}
              >
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">{evaluation.processName}</h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {formatDate(evaluation.completedAt)}
                    </p>
                  </div>
                  <button
                    onClick={(e) => handleClone(e, evaluation.id)}
                    className="flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                  >
                    <Copy className="h-4 w-4 mr-2" />
                    Clonar
                  </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div className="flex items-center text-gray-600">
                    <User className="h-5 w-5 mr-2 text-gray-400" />
                    <span>{evaluation.assessmentInfo.evaluatorName}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <MapPin className="h-5 w-5 mr-2 text-gray-400" />
                    <span>{evaluation.assessmentInfo.location}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Calendar className="h-5 w-5 mr-2 text-gray-400" />
                    <span>{evaluation.assessmentInfo.date}</span>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-6">
                    <div className="flex items-center">
                      <span className="text-sm text-gray-500 mr-2">
                        Efectividad:
                      </span>
                      <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-green-500 rounded-full"
                          style={{ width: `${calculateEffectiveness(evaluation)}%` }}
                        />
                      </div>
                      <span className="ml-2 text-sm font-medium text-gray-700">
                        {calculateEffectiveness(evaluation).toFixed(0)}%
                      </span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {evaluation.stages.length} etapas evaluadas
                    </span>
                  </div>
                  <ChevronRight className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedEvaluation && (
        <EvaluationDetail
          evaluation={selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
        />
      )}
    </div>
  );
}